import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  BigHighlight,
  BigHighlightContent,
  BigHighlightImg,
  BigHighlightSubtitle,
  BigHighlightTitle,
  BusinessItems,
  BusinessServices,
  BusinessServicesList,
  BusinessServicesListItem,
  CombinedServices,
  Container,
  CTAButton,
  DarkBlock,
  Features,
  FooterSection,
  GovernmentServices,
  GovernmentServicesList,
  GovernmentServicesListItem,
  GrayText,
  Hero,
  HeroItems,
  HeroSubtitleBreak,
  Highlight,
  HighlightSubtitle,
  HighlightTitle,
  MarketplaceButtons,
  PlusIcon,
  PlusIconWrap,
  TextHighLight,
} from "../styles/index.styles";

const HighlightItem = ({ item }) => {
  return (
    <Highlight>
      {item.icon}
      <Box>
        <HighlightTitle variant="h5">
          <Trans>{item.title}</Trans>
        </HighlightTitle>
        <HighlightSubtitle variant="p-sm">
          <Trans>{item.subtitle}</Trans>
        </HighlightSubtitle>
      </Box>
    </Highlight>
  );
};

const BigHighlightItem = ({ item, flip, heading, content }) => {
  return (
    <BigHighlight $flip={flip}>
      <BigHighlightImg>{item.img}</BigHighlightImg>
      <BigHighlightContent>
        <BigHighlightTitle>
          <Text variant="h2" as={heading}>
            <Trans>{item.title}</Trans>
          </Text>
        </BigHighlightTitle>
        <BigHighlightSubtitle variant="p-lg">
          <Trans>{item.subtitle}</Trans>
        </BigHighlightSubtitle>
        {content}
      </BigHighlightContent>
    </BigHighlight>
  );
};

const heroItems = [
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/id-card.svg"
        alt="ID card"
        layout="fixed"
        width={40}
        height={40}
      />
    ),
    title: "common.residency",
    subtitle: "public.homepage.heroitems.residency_subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/briefcase.svg"
        alt="Briefcase"
        layout="fixed"
        width={40}
        height={40}
      />
    ),
    title: "common.business",
    subtitle: "public.homepage.heroitems.business_subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/buildings.svg"
        alt="Apartment buildings"
        layout="fixed"
        width={40}
        height={40}
      />
    ),
    title: "common.real_estate",
    subtitle: "public.homepage.heroitems.real_estate_subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/pillars.svg"
        alt="Government building"
        layout="fixed"
        width={40}
        height={40}
      />
    ),
    title: "common.arbitration",
    subtitle: "public.homepage.heroitems.arbitration_subtitle",
  },
];

const features = [
  {
    img: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/illustrations/permit-card.svg"
        alt="Residency permit card"
      />
    ),
    title: "public.homepage.highlight_1.title",
    subtitle: "public.homepage.highlight_1.subtitle",
  },
  {
    img: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/illustrations/revenue-graph.svg"
        alt="Upward trending revenue graph"
      />
    ),
    title: "public.homepage.highlight_2.title",
    subtitle: "public.homepage.highlight_2.subtitle",
  },
  {
    img: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/illustrations/secure-services.svg"
        alt="Shield guarding services"
      />
    ),
    title: "public.homepage.highlight_3.title",
    subtitle: "public.homepage.highlight_3.subtitle",
  },
];

const businessItems = [
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/briefcase.svg"
        alt="Briefcase"
        layout="fixed"
      />
    ),
    title: "public.homepage.innovate.items.item_1.title",
    subtitle: "public.homepage.innovate.items.item_1.subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/hammer.svg"
        alt="Gavel"
        layout="fixed"
      />
    ),
    title: "public.homepage.innovate.items.item_2.title",
    subtitle: "public.homepage.innovate.items.item_2.subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/dollar.svg"
        alt="Dollar sign"
        layout="fixed"
      />
    ),
    title: "public.homepage.innovate.items.item_3.title",
    subtitle: "public.homepage.innovate.items.item_3.subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/chip.svg"
        alt="Computer chip"
        layout="fixed"
      />
    ),
    title: "public.homepage.innovate.items.item_4.title",
    subtitle: "public.homepage.innovate.items.item_4.subtitle",
  },
  {
    icon: (
      <StaticImage
        placeholder="tracedSVG"
        src="../images/duotone-icons/market.svg"
        alt="Market"
        layout="fixed"
      />
    ),
    title: "public.homepage.innovate.items.item_5.title",
    subtitle: "public.homepage.innovate.items.item_5.subtitle",
  },
];

const HomePage = ({ pageContext }) => {
  return (
    <>
      <Helmet>
        <title>ePróspera</title>
        <meta
          name="title"
          content="Próspera | e-Governance Platform | ePróspera"
        />
        <meta
          name="description"
          content="ePróspera is a digital platform providing entrepreneurs with access to multiple services and dedicated resources that make setting up a business simpler.  "
        />
      </Helmet>
      <GlobalSettings pageContext={pageContext} />
      <MainMenu darkFooter>
        <Hero>
          <Container>
            <Box marginBottom={1} maxWidth="890px">
              <Text variant="h2" as="h1">
                <Trans components={{ highlight: <TextHighLight /> }}>
                  public.homepage.hero.title
                </Trans>
              </Text>
            </Box>
            <Box marginBottom={4}>
              <Text variant="p-sm">
                <Trans components={{ linebreak: <HeroSubtitleBreak /> }}>
                  public.homepage.hero.subtitle
                </Trans>
              </Text>
            </Box>
            <CTAButton
              color="main"
              id="get-started"
              href={`${process.env.GATSBY_REDIRECT_URL}`}
            >
              <Trans>common.get_started</Trans>
            </CTAButton>
            <Box marginTop={4}>
              <HeroItems>
                {heroItems.map((heroItem) => (
                  <HighlightItem
                    key={`highlight-${heroItem.title.replaceAll(" ", "-")}`}
                    item={heroItem}
                  />
                ))}
              </HeroItems>
            </Box>
          </Container>
        </Hero>
        <Angles />
        <Container>
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth="575px"
              textAlign="center"
              marginTop={6.5}
              marginBottom={8}
            >
              <Box marginBottom={1.5}>
                <StaticImage
                  src="../images/logo-angles-green.svg"
                  alt=""
                  placeholder="tracedSVG"
                />
              </Box>
              <Box marginBottom={1.5}>
                <Text variant="h2">
                  <Trans>public.homepage.welcome.title</Trans>
                </Text>
              </Box>
              <Box>
                <Text variant="p-lg">
                  <Trans>public.homepage.welcome.subtitle</Trans>
                </Text>
              </Box>
            </Box>
          </Box>
          <Box marginTop={4} marginBottom={4}>
            <Features>
              {features.map((feature, index) => (
                <BigHighlightItem
                  item={feature}
                  flip={index % 2}
                  heading="h3"
                  key={`big-highlight-${feature.title.replaceAll(" ", "-")}`}
                />
              ))}
            </Features>
          </Box>
        </Container>
        <Angles flipHorizontal invertColors />
        <DarkBlock>
          <Container>
            <Box maxWidth="650px" marginBottom={6}>
              <Box marginBottom={1}>
                <Text variant="h2">
                  <Trans components={{ highlight: <TextHighLight /> }}>
                    public.homepage.innovate.title
                  </Trans>
                </Text>
              </Box>
              <Box>
                <GrayText>
                  <Trans>public.homepage.innovate.subtitle</Trans>
                </GrayText>
              </Box>
            </Box>
            <Box>
              <BusinessItems>
                {businessItems.map((businessItem) => (
                  <HighlightItem
                    key={`highlight-${businessItem.title.replaceAll(" ", "-")}`}
                    item={businessItem}
                  />
                ))}
              </BusinessItems>
            </Box>
          </Container>
        </DarkBlock>
        <Angles flipVertical invertColors />
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={6}
            marginBottom={3}
          >
            <Box textAlign="center">
              <Box marginBottom={1.5}>
                <Text variant="h2">
                  <Trans>public.homepage.platform.title</Trans>
                </Text>
              </Box>
              <Box>
                <Text variant="p-lg">
                  <Trans>public.homepage.platform.subtitle</Trans>
                </Text>
              </Box>
            </Box>
          </Box>
          <CombinedServices>
            <BusinessServices>
              <Box marginBottom={2}>
                <Text variant="h3">
                  <Trans>public.homepage.platform.business.title</Trans>
                </Text>
              </Box>
              <BusinessServicesList variant="checklist">
                {[...Array(5).keys()].map((i) => (
                  <BusinessServicesListItem key={`business-service-${i}`}>
                    <Trans>
                      {`public.homepage.platform.business.items.item_${i + 1}`}
                    </Trans>
                  </BusinessServicesListItem>
                ))}
              </BusinessServicesList>
            </BusinessServices>
            <PlusIconWrap>
              <PlusIcon>+</PlusIcon>
            </PlusIconWrap>
            <GovernmentServices>
              <Box marginBottom={2}>
                <Text variant="h3">Government services</Text>
              </Box>
              <GovernmentServicesList variant="checklist">
                {[...Array(5).keys()].map((i) => (
                  <GovernmentServicesListItem key={`government-service-${i}`}>
                    <Trans>
                      {`public.homepage.platform.government.items.item_${
                        i + 1
                      }`}
                    </Trans>
                  </GovernmentServicesListItem>
                ))}
              </GovernmentServicesList>
            </GovernmentServices>
          </CombinedServices>
          <Box marginTop={6.5} marginBottom={8}>
            <BigHighlightItem
              item={{
                img: (
                  <StaticImage
                    src="../images/illustrations/future-city.svg"
                    alt="Futuristic city"
                  />
                ),
                title: <Trans>public.homepage.marketplace.title</Trans>,
                subtitle: <Trans>public.homepage.marketplace.subtitle</Trans>,
              }}
              index={false}
              heading="h2"
              content={
                <Box marginTop={2.5}>
                  <Box marginBottom={2.5}>
                    <StaticImage
                      alt="Marketplace logos"
                      src="../images/marketplace_logos.png"
                    />
                  </Box>
                  <MarketplaceButtons>
                    <Button
                      id="apply-for-partnership"
                      href="https://prospera.hn/contact/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Trans>common.apply_for_partnership</Trans>
                    </Button>
                    <Link className="no-decoration" to="marketplace">
                      <Button
                        plain
                        id="marketplace"
                        variant="border"
                        rightIcon="arrow_forward"
                        as="div"
                      >
                        <Trans>common.marketplace</Trans>
                      </Button>
                    </Link>
                  </MarketplaceButtons>
                </Box>
              }
            />
          </Box>
        </Container>
        <Angles flipHorizontal invertColors />
        <FooterSection>
          <Box textAlign="center">
            <Box marginBottom={2.5}>
              <Text variant="h2">
                <Trans components={{ highlight: <TextHighLight /> }}>
                  public.homepage.footer.title
                </Trans>
              </Text>
            </Box>
            <CTAButton
              id="join_now"
              color="main"
              href={`${process.env.GATSBY_REDIRECT_URL}`}
            >
              <Trans>common.join_now</Trans>
            </CTAButton>
          </Box>
        </FooterSection>
      </MainMenu>
    </>
  );
};

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
