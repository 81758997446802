import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Button from "@vertikal/e-prospera.ui.button";
import { List, ListItem } from "@vertikal/e-prospera.ui.list";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";
import HomePageFooterMap from "../images/homepage_footer_map.png";
import HomePageHeroMap from "../images/homepage_hero_map.png";
import HomePageHeroMapMob from "../images/homepage_hero_map_mob.png";

export const Container = styled.div`
  max-width: 1250px;
  margin: auto;
  padding: 0 1.5rem;
  @media ${breakpoints.md} {
    padding: 0 2.5rem;
  }
`;

export const Hero = styled.div`
  background-image: ${`url("${HomePageHeroMapMob}")`};
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${colors.accent.c900};
  background-size: contain;
  color: ${pureColors.white};
  padding: 5rem 0;
  @media ${breakpoints.md} {
    background-image: ${`url("${HomePageHeroMap}")`};
    padding: 10rem 0 7rem;
  }
`;

export const HeroSubtitleBreak = styled.span`
  @media ${breakpoints.md} {
    display: block;
  }
`;

export const HeroItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  @media ${breakpoints.md} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  @media ${breakpoints.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Highlight = styled.div`
  color: ${pureColors.white};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
`;

export const HighlightTitle = styled(Text)`
  margin-bottom: 0.75rem;
`;

export const HighlightSubtitle = styled(Text)`
  color: ${colors.gray.c300};
`;

export const TextHighLight = styled.span`
  color: ${colors.success.c400};
`;

export const CTAButton = styled(Button).attrs(() => ({ focusColor: "light" }))`
  min-width: 13.75rem;
  width: 100%;
  @media ${breakpoints.md} {
    width: auto;
  }
`;

export const Features = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 8rem;
`;

export const BigHighlight = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoints.lg} {
    justify-content: space-between;
    flex-direction: ${(props) => (props.$flip ? "row-reverse" : "row")};
    align-items: center;
  }
`;

export const BigHighlightImg = styled.div`
  margin-bottom: 1.5rem;
  @media ${breakpoints.lg} {
    flex: 0 1 50%;
    margin-bottom: 0;
  }
  > img {
    width: 100%;
    max-width: 575px;
  }
`;

export const BigHighlightContent = styled.div`
  @media ${breakpoints.lg} {
    flex: 0 1 40%;
    padding: 5rem;
  }
`;

export const GrayText = styled(Text)`
  color: ${colors.gray.c300};
`;

export const BigHighlightTitle = styled.div`
  margin-bottom: 1.5rem;
  @media ${breakpoints.lg} {
    margin-bottom: 2.5rem;
  }
`;

export const BigHighlightSubtitle = styled(Text)``;

export const DarkBlock = styled.div`
  padding: 6rem 0 10rem;
  color: ${pureColors.white};
  background: ${colors.accent.c900};
`;

export const BusinessItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  @media ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CombinedServices = styled.div`
  display: grid;
  max-width: 62.5rem;
  margin: auto;
  @media ${breakpoints.mdDown} {
    grid-template-rows: 1fr auto 1fr;
  }
  @media ${breakpoints.md} {
    grid-template-columns: 1fr auto 1fr;
  }
`;

export const BusinessServices = styled.div`
  background: ${colors.accent.c800};
  color: ${pureColors.white};
  @media ${breakpoints.mdDown} {
    padding: 2rem;
    border-radius: 1rem 1rem 0 0;
  }
  @media ${breakpoints.md} {
    padding: 4rem;
    border-radius: 1rem 0 0 1rem;
  }
`;

export const GovernmentServices = styled.div`
  background: ${colors.success.c200};
  @media ${breakpoints.mdDown} {
    padding: 2rem;
    border-radius: 0 0 1rem 1rem;
  }
  @media ${breakpoints.md} {
    padding: 4rem;
    border-radius: 0 1rem 1rem 0;
  }
`;

export const ServicesList = styled(List)``;

export const BusinessServicesList = styled(ServicesList)``;

export const GovernmentServicesList = styled(ServicesList)`
  background: ${colors.success.c200};
`;

export const ServicesListItem = styled(ListItem)`
  padding-left: 2rem;
  > .material-icons-round {
    font-size: 1.5rem;
    top: 0;
  }
`;

export const GovernmentServicesListItem = styled(ServicesListItem)``;

export const BusinessServicesListItem = styled(ServicesListItem)`
  color: ${pureColors.white};
  > .material-icons-round {
    color: ${colors.success.c400};
  }
`;

export const PlusIconWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.success.c500};
  @media ${breakpoints.mdDown} {
    margin: -1rem 0;
  }
  @media ${breakpoints.md} {
    margin: 0 -1rem;
  }
`;

export const PlusIcon = styled(Text).attrs(() => ({ variant: "h2" }))`
  background: ${pureColors.white};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6.25rem;
  font-size: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.success.c500};
`;

export const MarketplaceLogos = styled.img`
  max-width: 100%;
`;

export const MarketplaceButtons = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoints.mdDown} {
    > *:first-child {
      margin-bottom: 1rem;
    }
  }
  @media ${breakpoints.md} {
    flex-direction: row;
    > *:first-child {
      margin-right: 1rem;
    }
  }
`;

export const FooterSection = styled.div`
  background: ${`url("${HomePageFooterMap}") ${colors.accent.c900}`} no-repeat
    center center;
  background-size: contain;
  padding: 8rem 1.5rem;
  color: ${pureColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${breakpoints.md} {
    padding: 18rem 2.5rem;
  }
`;
